import Vue from 'vue'
import VueRouter from 'vue-router'
const HomeView = () =>import('@/views/HomeView.vue')
const IndexView = () =>import('@/views/index/IndexView.vue')
const AirticleView = () =>import('@/views/AirticleView/AirticleView')
const ProductItem = () =>import('@/views/Product/ProductItem')
const ProductList = ()=>import('@/views/Product/ProductList')
const ArtList = ()=>import('@/views/AirticleView/ArcList')
const OdmView = ()=>import('@/views/Odm/OdmView')
const QuestionDetail = ()=>import('@/views/Question/QuestionDetail')
const QuesList = ()=>import('@/views/Question/QuesList')
const Encyclopedias = ()=>import('@/views/encyclopedias/encyclopediasView')
const ServiceView = ()=>import('@/views/Service/ServiceView')
const ExamplesView = ()=>import('@/views/Examples/ExamplesView')
const AboutusView = ()=>import('@/views/aboutus/aboutusView')
const ExamplesDetail = ()=>import('@/views/Examples/ExamplesDetail')
const WaterExamples = ()=>import('@/views/Examples/waterExamples')
const GkExampless = ()=>import('@/views/Examples/GkExamples')
const TrFive = ()=>import('@/views/3dShow/TrFive')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    redirect:"/index",
    children:[
      {
        path:'/index',
        component:IndexView,
        meta: { keepAlive: true}
      },
      {
        path:'/encyclopedias',
        component:Encyclopedias,
        name:"物联百科"
      },
      {
        path:'/hyzs',
        component:AirticleView,
        name:'行业知识内页'
      },
      {
        path:'/hyzslist',
        component:ArtList,
        name:'行业知识'
      },
      {
        path:'/hydc',
        component:AirticleView,
        name:'行业洞察内页'
      },
      {
        path:'/hydcList',
        component:ArtList,
        name:'行业洞察'
      },
      {
        path:' /tag',
        component:ArtList,
        name:'Tag标签'
      },
      {
        path: '/cjal',
        component:AirticleView,
        name:'场景案例内页'
      },
      {
        path: '/cjalList',
        component:ArtList,
        name:'场景案例'
      },
      {
        path: '/questions',
        component:QuestionDetail,
        name:'技术问答内页'
       
      },
      {
        path: '/quesList',
        component:QuesList,
        name:'技术问答'
      },
     
      {
        path:'/iotlist',
        component:ProductItem,
        name:"产品详情"
      },
      {
        path:'/productList',
        component:ProductList,
        name:"产品列表"
      },
      {
        path:'/odm',
        component:OdmView,
        name:"Odm"
      },
      {
        path:'/download',
        component:ServiceView,
        name:"服务支持"
      },
      {
        path:'/examples',
        component: ExamplesView,
        name:"解决方案"
      },
      {
        path:'/aboutus',
        component:AboutusView,
        name:"关于星创"
      },
      {
        path:'/examplesDetail',
        component:ExamplesDetail,
        name:"具体解决方案"
      },
      {
        path:'/waterExamples',
        component:WaterExamples,
        name:"智慧水利解决方案"
      },
      {
        path:'/gangkouExamples',
        component:GkExampless,
        name:"智慧港口解决方案"
      },
      {
        path:'/3d',
        component:TrFive,
        name:"3d"
      },


      
    ]
   
  },
]


const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // 获取跳转前的URL地址
  const previousUrl = window.location.href
  // 将URL地址存储到sessionStorage中
  sessionStorage.setItem('previousUrl', previousUrl)
  next()
})


export default router
