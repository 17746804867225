import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/js/response'
import './assets/js/playersolute'
import md5 from 'js-md5';
import '@/plugins/vant'
import VueCoreVideoPlayer from 'vue-core-video-player'
import store from './store'
import BaiduMap from 'vue-baidu-map'
import MetaInfo from 'vue-meta-info';

Vue.use(MetaInfo)
Vue.use(VueCoreVideoPlayer)
Vue.config.productionTip = false;
Vue.prototype.$md5 = md5;
Vue.use(BaiduMap, { ak: '6U2NSKTnN0AvidHBUqVMteP2BIaPg15g' });

// router.beforeEach((to, from, next) => {
//   if (to.path === from.path) {
//     // 如果目标路径与当前路径相同，阻止导航
//     console.log('重复导航！');
//     return;
//   }
//   next();
// });
 Vue.filter('stripTags', function(value) {
  const cleanHtml = SanitizeHtml(value, {
    allowedTags: [],
    allowedAttributes: {},
  });
  return cleanHtml;
});
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')