
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    SRfourArray: [] // 定义一个空数组
  },
  mutations: {
    setSRfourArray (state, payload) {
      state.SRfourArray = payload
    }
  },
  actions: {
    loadSRfourArray ({ commit }) {
      return fourgFun() // 在 actions 中调用您的函数
        .then(products => {
          commit('setSRfourArray', products) // 分派 mutation 来更新 SRfourArray
        })
    }
  }
})

