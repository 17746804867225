import Vue from 'vue'
import { NavBar,Button,Tab,Tabs,Swipe,SwipeItem,Icon,
Popup,Cell,Toast,Image,Col,Row,Loading,Dialog,Pagination,Form,Field,Area,ImagePreview} from "vant";
Vue.use(NavBar)
Vue.use(Button);
Vue.use(Tab)
Vue.use(Tabs);
Vue.use(Swipe)
Vue.use(SwipeItem);
Vue.use(Icon);
Vue.use(Popup);
Vue.use(Cell);
Vue.use(Toast);
Vue.use(Image);
Vue.use(Col);
Vue.use(Row);
Vue.use(Loading);
Vue.use(Dialog);
Vue.use(Pagination);
Vue.use(Form);
Vue.use(Field);
Vue.use(Area);
Vue.use(ImagePreview);



